section.modal {
    background-color: #000;
    min-height: 100%;
    margin-left: -24px;
    position: absolute;
    top: -10px;
    width: 114%;
    overflow-y: scroll;
}

section.modal .text-bigger {
    margin-left: 60px;
}

section.modal li {
    border-bottom: 1px solid #fff;
    height: 50px;
    list-style-type: none;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 30px;
}

section.modal li .text-bold {
    margin-bottom: 40px;
    margin-left: 25px;
    margin-right: 25px;
}

section.modal img {
    float: right;
    position: absolute;
    right: 38px;
    top: 38px;
}
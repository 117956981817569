.text-regular {
    font-family: Roboto-Regular;
}
  
.text-bold {
    font-family: Roboto-Bold;
}

.text-regular, .text-bold {
    line-height: 22px;
}
  
.text-small {
    font-size: 12px;
}
  
.text-normal {
    font-size: 16px;
}
  
.text-big {
    font-size: 20px;
}

.text-bigger {
    font-size: 32px;
}
select {
  appearance: none;
  background-color: #000;
  border: none;
  color: #fff;
  height: 40px;
  width: 100%;
  padding: 10px;
  -webkit-appearance: none;
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: "⌄";
  font-size: 1rem;
  top: 6px;
  right: 12px;
  position: absolute;
}
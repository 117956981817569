@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto-Bold'), url(./assets/fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
}

body {
  background-color: #231f20;
  font-family: Roboto;
  color: #fff;
}

.container {
  left: 50%;  
  margin-left: -45%;
  position: absolute;
  width: 90%;
}

main {
  margin-top: 75px;
  width: 90%;
}

ul {
  padding: 0;
}

.map .text-big {
  margin-bottom: -8px;
}

.map .text-small {
  margin-bottom: 16px;
}

.loader {
  font-size: 6px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 9em;
  height: 9em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.loader:before {
  width: 50%;
  height: 50%;
  background: #fff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.loader:after {
  background: #231f20;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (min-width: 480px) {
  .container {
    margin-left: -240px;
    width: 480px;
  }

  main {
    /* width: 480px; */
    /* margin-left: -240px; */
    /* margin-right: 5%; */
  } 
}